export const projects = [
  {
    name: 'RGA Website',
    tools: 'React | TailwindCSS | Nodemailer',
    description: 'The official website for Robert Guild Associates, showcasing services and capabilities.',
    repoUrl: 'https://github.com/alialoraebi/rga-website',
  },  
  {
    name: 'Employee Management App',
    tools: 'React | CSS | Docker | MongoDB',
    description: 'A React web application to manage employee records and schedules.',
    repoUrl: 'https://github.com/alialoraebi/emp_management_app',
  },
  {
    name: 'Study Time Tracker App',
    tools: 'JavaFX | Gradle | Bootstrap CSS',
    description: 'A tool for tracking and storing study sessions.',
    repoUrl: 'https://github.com/alialoraebi/Study_Time_Tracker',
  },
  {
    name: 'F1 23 Results App',
    tools: 'React | TailwindCSS | Node.js | Express.js | MongoDB',
    description: 'A React web application that allows F1 23 players to add their own race results and compare it with real racers.',
    repoUrl: 'https://github.com/alialoraebi/F1_23_App',
  },
  {
    name: 'Digital Divide',
    tools: 'Python',
    description: 'A Python project that focuses on understanding the impact of the digital divide within Canada.',
    repoUrl: 'https://github.com/alialoraebi/digital-divide',
  },
  {
    name: 'Library System',
    tools: 'Python',
    description: 'Library program written in Python that allows the user to display, search and rent books',
    repoUrl: 'https://github.com/alialoraebi/Library_Program',
  },
];